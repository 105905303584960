<template>
  <div class="ClippingListItem">
    <div class="ClippingListItem__thumb">
      <div>
        <div class="image is-2by1">
          <div :style="{ backgroundImage }" class="floated is-cover-image to-top hf_value_wrap" />
        </div>
      </div>
      <div class="Clipping-overlay">
        <div v-if="!readOnly" class="Clipping-overlay__actions columns is-mobile is-marginless is-gapless">
          <div class="column is-narrow">
            <restricted-button
              :permission="true"
              tag="a"
              action-class="action action--export"
              @click="exportClipping"
            >
              <i v-if="!isExporting" class="hf hf-file-pdf-o" />
              <i v-if="isExporting" class="hf hf-pulse hf-spinner" />
            </restricted-button>
          </div>
          <div class="column is-narrow m-l-s">
            <restricted-button
              tag="a"
              permission="clipping.update"
              action-class="action action--edit"
              @click="editClipping"
            >
              <i class="hf hf-pencil" />
            </restricted-button>
          </div>
          <!--          <div class="column is-narrow m-l-s">-->
          <!--            <restricted-button-->
          <!--              :permission="true"-->
          <!--              :to="{ name:'emails.new', query:{ entity_id: clipping.id, type: 'clipping' } }"-->
          <!--              tag="a"-->
          <!--              action-class="action action&#45;&#45;email"-->
          <!--            >-->
          <!--              <i class="hf hf-envelope" />-->
          <!--            </restricted-button>-->
          <!--          </div>-->
          <div class="m-l-a column is-narrow m-l-s">
            <restricted-button
              tag="a"
              permission="clipping.delete"
              action-class="action action--delete"
              @click.prevent="deleteClipping"
            >
              <i v-if="!isLoading" class="hf hf-trash" />
              <i v-if="isLoading" class="hf hf-pulse hf-spinner" />
            </restricted-button>
          </div>
        </div>
        <div class="Clipping-overlay__content" @click="openClippingPreview">
          <div class="Clipping-overlay__publish-date">
            {{ datetime(clipping.published_at, 'Do MMMM YYYY') }}
          </div>
          <div class="Clipping-overlay__headline">
            <template v-if="clipping.data.translated_headline">
              {{ truncate(clipping.data.translated_headline, 50) }}
            </template>
            <template v-else>
              {{ truncate(clipping.data.headline, 50) }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="ClippingListItem__body is-full-width">
      <div class="columns is-gapless">
        <div class="column is-10">
          <div class="ClippingListItem__details">
            <div class="ClippingListItem__source has-text-weight-bold">
              {{ clipping.media_outlet.data.name }}
            </div>
            <div class="ClippingListItem__country is-size-7">
              <i class="hf hf-globe" /> {{ country }}
            </div>
          </div>
        </div>

        <div class="column is-2 is-column is-aligned-middle is-aligned-bottom">
          <div class="ClippingListItem__icons">
            <div class="icon is-round has-background-dark">
              <clipping-type-icon :type="clipping.type" />
            </div>
            <div class="is-flex m-t-s is-aligned-center">
              <div
                v-if="hasTier"
                :class="{ 'm-r-s': $slots.icons && $slots.icons.length }"
                class="icon is-round has-text-weight-bold"
              >
                <tier :tier="tier" />
              </div>
              <slot name="icons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

import { isImage } from '@hypefactors/shared/js/utils/index'
import ClippingTypeIcon from '@hypefactors/shared/js/components/clippings/ClippingTypeIcon'
import { ImageResizerService } from '@hypefactors/shared/js/services/ImageResizerService'

import { ClippingsApi } from '@/services/api/ClippingsApiService'

/**
 * Renders a clipping card on the Coverage page
 * @module ClippingListItem
 */
export default {
  components: {
    ClippingTypeIcon
  },
  mixins: [TruncateMixin, DatetimeMixin],
  props: {
    clipping: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isLoading: false,
      isExporting: false
    }
  },

  computed: {
    hasTier () {
      return !!this.tier && this.tier !== 'no-tier'
    },

    tier () {
      return this.$safeGet(this.clipping, 'tier', 'no-tier')
    },

    country () {
      return this.$safeGet(this.clipping.country.data, 'name')
    },

    backgroundImage () {
      const thumbnail = this.$safeGet(this.clipping, 'thumbnail[0]')
      const file = this.$safeGet(this.clipping, 'files[0].url')
      const fallback = this.$asset('/images/sections/performance/fallback_clipping_thumbnail.png')
      const bgImage = thumbnail || (isImage(file) && file) || fallback
      const resizedImage = ImageResizerService.resize(bgImage, 400, undefined, { quality: 80 })
      return `url(${resizedImage})`
    }
  },

  methods: {
    async exportClipping () {
      const windowName = `Clipping ${this.clipping.id}`

      this.isExporting = true

      const width = 600
      const height = 800

      const left = (screen.width / 2) - (width / 2)
      const top = (screen.height / 2) - (height / 2)

      var win = window.open('about:blank', windowName, `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height},top=${top},left=${left}`)

      try {
        win.document.write('<table cellpadding="0" cellspacing="0" border="0" width="100%" height="100%"><tr><td align="center"><img src="https://storage.hypefactors.com/images/general/loading-animation.gif"></td></tr></table>')

        const response = await ClippingsApi.exportClipping(this.clipping.id)

        const blob = new Blob([response.data], { type: 'application/pdf' })

        const url = win.URL.createObjectURL(blob)

        win.document.body.innerHTML = `<embed src="${url}" type="application/pdf" width="100%" height="100%" />`
      } catch (e) {
        win.close()

        this.$notify.error('There was an error while generating the PDF')
      } finally {
        this.isExporting = false
      }
    },
    editClipping () {
      this.$store.dispatch('clippings/add/prepareClippingForEdit', this.clipping)
      this.$router.push({ name: 'performance.coverage.clipping.edit', params: { clippingId: this.clipping.id } })
    },
    async deleteClipping () {
      try {
        await this.$confirm(this.$t('components.clipping_list_item.confirm_remove_clipping'))
        this.isLoading = true
        await ClippingsApi.deleteClipping(this.clipping.id)
        this.$notify.success(this.$t('success.clipping_removed'))
        this.$emit('removed', this.clipping)
      } catch (e) {
        if (e === 'cancel') return
        this.$displayRequestError(e)
      } finally {
        this.isLoading = false
      }
    },
    openClippingPreview () {
      this.$bus.$emit('ClippingPreviewModal:open', this.clipping)
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.ClippingListItem {
  display: flex;
  width: 100%;
  background: $grey-lighter;
  border: 1px solid $grey-lighter;
  flex-flow: column wrap;

  .Clipping-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    padding: $padding;
    color: $primary-invert;
    pointer-events: none;
    background: transparentize($primary, 0.18);
    transition: 0.1s ease-in 0s;
    flex-flow: column nowrap;

    @include desktop {
      opacity: 0;
      transform: translateY(100%);
    }

    &__actions {
      width: 100%;
      margin-bottom: $margin !important;
      font-size: 1.5em;
      @include desktop {
        @include delayAppearance;
      }

      .action {
        color: $primary-invert;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
      width: 100%;
      cursor: pointer;
      @include desktop {
        @include delayAppearance;
      }
    }

    &__publish-date {
      font-size: $size-7;
    }

    &__headline {
      font-size: 1.1em;
      color: $white-ter;
    }
  }

  &:hover {
    .Clipping-overlay {
      @include appearDelayed;
      pointer-events: all;

      &__actions {
        @include appearDelayed;
      }

      &__content {
        @include appearDelayed;
        transition-delay: 0.15s;
      }
    }
  }

  &__thumb {
    flex: 1 1 auto;
    position: relative;
    width: 100%;
    overflow: hidden;

    .hf_value_wrap {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: $white;
      font-size: $size-4;
      text-shadow: 1px 0 0 $dark, 0 1px 0 $dark, -1px 0 0 $dark, 0 -1px 0 $dark;
      font-weight: bold;
      padding: $padding;
      line-height: 1;
    }
  }

  &__body {
    padding: $padding;
    font-size: $size-6;
  }

  &__source {
    width: 100%;
    margin-bottom: $margin-small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icons {
    .icon {
      display: block;
      text-align: center;

      + .icon {
        margin-top: $margin-tiny;
      }
    }
  }
}
</style>
